import { Box } from '@mui/material';
import Loader from 'components/atoms/Loader/Loader';
import { useOutletContext } from 'react-router-dom';
import InstrumentalSubHeader from 'components/organisms/InstrumentalSubHeader/InstrumentalSubHeader';
import EmptyPage from 'components/atoms/EmptyPage/EmptyPage';
import { StoresListContextInterface } from 'types/outlet_context_models';
import MessageButton from 'components/molecules/ToolsButtons/MessageButton';
import { DialogWindowType } from 'layouts/hooks/useFormsApp';
import StoreListCard from 'components/organisms/Cards/StoreListCard';
import Grid from '@mui/material/Unstable_Grid2';
import ElasticSearch from 'components/molecules/ToolsButtons/ElasticSearch';
import { useEffect, useState } from 'react';
import { scrollPage } from 'utils/scrollPage';
import { useDevice } from 'hooks/useDevice';
// import TypesFilter from 'components/organisms/Filters/TypesFilter';
// import ClearFilters from 'components/atoms/Buttons/ClearFilters';

const MarketPage = () => {
    const {
        footerMenuHeight,
        handleOpenDialog,
        storesList,
        subscribeList,
        refreshSubscriptionsList,
        instrumentalBarHeight,
        headerHeight,
        marketScrollPosition,
        setMarketScrollPosition,
        isStoresListLoading,
    }: StoresListContextInterface = useOutletContext();
    const [searchValue, setSearchValue] = useState('');
    // const [filterTypes, setFilterTypes] = useState<null | number[]>(null);
    const { sx } = useDevice();
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => {
        setIsLoading(false);
    }, 300);

    useEffect(() => {
        if (marketScrollPosition) {
            setTimeout(
                () => {
                    scrollPage(marketScrollPosition - (instrumentalBarHeight + headerHeight));
                    setMarketScrollPosition(0);
                },
                sx ? 50 : 100
            );
            return;
        }
    }, []); // eslint-disable-line

    const filteredStores = storesList?.filter(store => {
        const matchesSearch = store?.name?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase());
        // const matchesType = filterTypes ? store?.store?.types?.some(el => filterTypes.includes(el)) : true;
        return matchesSearch;
        // && matchesType;
    });

    return (
        <Box pt={2} px={2} overflow="hidden">
            {(isStoresListLoading || isLoading) && <Loader isShown />}
            <MessageButton action={() => handleOpenDialog(DialogWindowType?.QUESTION)} />
            <InstrumentalSubHeader
                EndSlot={() => (
                    <Box sx={{ display: 'flex', gap: 0.75 }}>
                        {/* <ClearFilters
                        disabled={!filterTypes?.length}
                        action={() => {
                            setSearchValue('');
                            setFilterTypes(null);
                        }}
                    /> */}
                        <ElasticSearch inputValue={searchValue} onChange={val => setSearchValue(val)} />
                    </Box>
                )}
            />
            <Grid
                sx={{ pb: `${footerMenuHeight + 60}px`, opacity: isLoading ? 0 : 1 }}
                className="CatalogStoreList"
                container
                spacing={2}
            >
                {/* <TypesFilter setFilterTypes={setFilterTypes} filterTypes={filterTypes} /> */}

                {filteredStores.length ? (
                    filteredStores.map(store => (
                        <StoreListCard
                            key={store?.id}
                            data={store}
                            subscribeList={subscribeList}
                            refreshSubscriptionsList={refreshSubscriptionsList}
                        />
                    ))
                ) : (
                    <EmptyPage isShown />
                )}
            </Grid>
        </Box>
    );
};

export default MarketPage;
