import { ThemeProvider } from '@mui/material';
import mainTheme from 'theme/mainTheme';
import StoresRouting from 'router/StoresRouting';
import { Toaster } from 'react-hot-toast';
import { useDevice } from 'hooks/useDevice';
import HeadStoresHTML from 'layouts/Head-Stores-HTML';
import { useEffect } from 'react';
import { telegramSender } from 'utils/telegramSender';

const App = () => {
    const { sx } = useDevice();

    useEffect(() => {
        const userAgent = navigator.userAgent;

        const referrer = document.referrer || 'Direct traffic';
        telegramSender({
            action: `VISIT alberto bini SHOP REF ${referrer} AGENT ${userAgent}`,
        });
    }, []);

    return (
        <ThemeProvider theme={mainTheme}>
            <Toaster
                position="top-right"
                toastOptions={{ style: { width: '100vw', maxWidth: sx ? '100vw' : '' }, duration: 3000 }}
            />

            <HeadStoresHTML />
            <StoresRouting />
        </ThemeProvider>
    );
};

export default App;
