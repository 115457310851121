import { useEffect, useState } from 'react';
import { INFORMATION } from 'dataBase/translations/information';
import { HOME_PAGE } from 'dataBase/translations/home_page';
import { COUNTRIES } from 'dataBase/translations/countries';
import { LONG_PHRASES } from 'dataBase/translations/long_phrases';
import { SUPPORTED_APP_LANG } from 'dataBase/translations/supported_app_lang';
import { APP_INFO } from 'dataBase/translations/app_information';
import { ADDITIONAL } from 'dataBase/translations/additional';

interface Props {
    lang: string;
    storeName: string;
}
export interface LanguageDataInterface {
    code: string;
    string: Record<string, any>;
}

export interface LangResInterface {
    currentLanguage: LanguageDataInterface;
}

export const useGetLanguage = ({ lang, storeName }: Props): LangResInterface => {
    const [currentLanguage, setCurrentLanguage] = useState({ code: '', string: {} });

    const langList = [
        {
            code: 'en',
            string: {
                ...SUPPORTED_APP_LANG?.en,
                ...LONG_PHRASES?.en,
                ...INFORMATION?.en,
                ...HOME_PAGE?.en,
                ...COUNTRIES?.en,
                ...APP_INFO?.en,
                ...ADDITIONAL.en,
                cocktail_market: 'Alberto Bini Market',
                click_here_to_ask_for_wholesale_pricing_information:
                    'Click here to ask for wholesale pricing information',
                get_pricing: 'Get pricing',
                pricing_form_description: 'Request Wholesale Pricing and Minimum Order Information',
                want_to_know_all_prices: 'Want to know all catalog prices',
                selected_model: 'Selected model',
                hero_text: `An online product catalog is a software solution that allows creating electronic catalogs with product descriptions. It provides features for easy addition of products, classification, description, adding photos, and other important information. Users can organize products into different categories, set up convenient filters, perform searches to simplify navigation. It is often used by companies to create virtual trade catalogs, visualize preliminary projects, online stores, present products at exhibitions, and presentations.`,
                to: 'to',
                in: 'in',
                no_available_sizes: 'No available sizes',
                image_not_loaded: 'Image not loaded',
                no_categories_available: 'No categories available',
                back_to_shopping: 'Back To Shopping',
                order_sent_successfully: 'Order Sent Successfully',
                deleted_by_owner: 'Deleted By Owner',
                details: 'Details',
                final_price: 'Final Price',
                quantity: 'Quantity',
                size: 'Size',
                processed: 'Processed',
                ordered: 'Ordered',
                delivered: 'Delivered',
                refunded: 'Refunded',
                canceled: 'Canceled',
                order: 'Order',
                ordered_date: 'Order Date',
                personal_data: 'Personal Data',
                delivery_data: 'Delivery Data',
                update: 'Update',
                profile: 'Profile',
                orders: 'Orders',
                clear_favorites: 'Clear favorites',
                not_required: 'Not required',
                excluding_delivery: 'Excluding delivery',
                total: 'total',
                delivery_address: 'Delivery address',
                delivery_information: 'Delivery information',
                confirm_order: 'Confirm order',
                no_sizes_added: 'No sizes added',
                delete: 'Delete',
                select_sizes: 'Select Sizes',
                shown: 'Shown',
                languages: 'Languages',
                back_to_stores: 'Back to stores',
                ok: 'Ok',
                search: 'Search',
                language: 'Language',
                wrong_key: 'Wrong key!',
                approve: 'Approve',
                ready: 'Ready',
                clear_filters: 'Clear filters',
                clear: 'Clear',
                filters: 'Filters',
                nothing_was_found: 'Nothing was found',
                stores: 'Stores',
                catalog: 'Catalog',
                find_store_by_name: 'Find store by name',
                favorites: 'Favorites',
                my_stores: 'My stores',
                login: 'Login',
                register: 'Register',
                forgot_password: 'Forgot password?',
                cart: 'Cart',
                vendor_code: 'Product code',
                price: 'Price',
                logout: 'Logout',
                submit: 'Submit',
                description: 'Description',
                added: 'Added',
                add_to: 'Add To',
                first_name: 'First Name',
                last_name: 'Last Name',
                phone_number: 'Phone Number',
                city: 'City',
                email: 'Email',
                password: 'Password',
                confirm_password: 'Confirm password',
                company_name: 'Company Name',
                already_registered: 'Already registered',
                cancel: 'Cancel',
                clear_cart: 'Clear cart',
                do_you_want_to_clear_cart: 'Do you want to clear cart?',
                enter_password: 'Enter password',
                enter_email: 'Enter email',
                enter_valid_email: 'Enter valid email',
                password_length_min_4_symbols: 'Password length min 4 symbols',
                passwords_do_not_match: 'Passwords do not match',
                enter_first_name: 'Enter first name',
                enter_last_name: 'Enter last name',
                enter_phone_number: 'Enter phone number',
                my_profile: 'My profile',
                country: 'Country',
                e_a: 'e.a.',
                reset_password: 'Reset Password',
                close: 'Close',
                find_by_vendor_code: 'Find by product code',
                colors: 'Colors',
                color: 'Color',
                sizes: 'Sizes',
                contacts: 'Contacts',
                name: 'Name',
                no_results: 'No results',
                loading: 'Loading',
                phone_length_minimum_7_characters: 'Phone length minimum 7 characters',
                phone_length_max_14_characters: 'Phone length maximum 14 characters',
                size_chart: 'Size chart',
                load_more: 'Load more',
                out_of: 'out of',
                models: 'models',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'In order to use this option, you must log in or register.',
                you_need_to_login: 'You need to login!',
                sended: 'Sended',
                store_key: 'Store key',
                enter_store_key: 'Enter store key!',
                language_error: 'Language error!',
                support: 'Support',
                ask_question: 'Ask question',
                about_store: 'About Store',
                payments_delivery: 'Payments & Delivery',
                return_exchange: 'Returns & Exchange',
                privacy_policy: 'Privacy Policy',
                info_title_1: `About Alberto Bini Shop`,
                info_title_2: 'Payments & Delivery',
                info_title_3: 'Returns & Exchange',
                info_title_4: 'Privacy Policy',
                message: 'Message',
                info: 'Info',
                item: 'item(s)',
                add_more: 'Add more',
                retail_catalog: 'Retail',
                wholesale_catalog: 'Wholesale',
                web_mob_view: 'Web and Mobile View',
                examples: 'Examples',
                main_advantages: 'Main Advantages',
                online_catalog_for_your_business: 'Online Catalog for your business',
                first_month_free: 'First month free',
                home: 'Home',
                main_function: `Main Functionality`,
                admin_panel: `Admin Panel`,
                admin: `Administrators`,
                products_models_photo: `Products/Models/Photos`,
                catalog_lang: `Catalog Languages`,
                customization: `Customization`,
                owners: `For Owner`,
                customers: `For Customer`,
                request: `Request`,
                show_all: `Show All`,
                unlimited: `Unlimited`,
                month: `Month`,
                i_want: `I Want to Order`,
                request_catalog: `Request Catalog`,
                consultation: `Request Consultation`,
                request_example: `Request Example`,
                plan: `Plan`,
                comment: `Comment`,
                required_field: 'Required field',
                question: 'Question',
                send: 'Send',
                request_sended_successfully: 'The request has been sent successfully! We will contact you shortly.',
                user_not_registered: 'User with this email is not registered',
                do_want_to_logout: 'Are you sure you want to leave your account?',
                enter_new_password: 'Enter new password',
                clear_form: 'Clear form',
                password_changed: 'Password changed',
                add_to_cart: 'Add to cart',
                in_cart: 'In cart',
                menu: 'Menu',
                save_delivery_info: 'Save delivery info',
                available_sizes: 'Available sizes',
                important: 'Important',

                copied_to_clipboard: 'Copied to clipboard',
                security_check: 'Security check',
                more: 'more',
                less: 'less',
                find_by_store_name: 'Find by store name',
                subscribes: 'Subscriptions',
                view_your_subscriptions_please_register_or_login:
                    'To view your subscriptions, please register or log in.',

                go_to: 'Go to',
                discounts_: 'Discounts',
                support_team: 'Support Team',
            },
        },

        {
            code: 'ua',
            string: {
                ...SUPPORTED_APP_LANG?.ua,
                ...LONG_PHRASES?.ua,
                ...COUNTRIES?.ua,
                ...INFORMATION?.ua,
                ...HOME_PAGE?.ua,
                ...APP_INFO?.ua,
                ...ADDITIONAL.ua,
                cocktail_market: 'Alberto Bini Маркет',
                click_here_to_ask_for_wholesale_pricing_information:
                    'Клiкнiть тут, щоб запитати інформацію про оптові ціни',
                get_pricing: 'Отримати ціни',
                pricing_form_description: 'Запит оптових цін та інформації про мінімальне замовлення',
                want_to_know_all_prices: 'Хочете дізнатися всі ціни з каталогу',
                selected_model: 'Обрана модель',

                to: 'до',
                in: 'в',
                no_available_sizes: 'Немає доступних розмірів',
                image_not_loaded: 'Зображення не завантажено',
                no_categories_available: 'Немає доступних категорій',
                back_to_shopping: 'Назад до покупок',
                order_sent_successfully: 'Замовлення успішно відправлено',
                deleted_by_owner: 'Видалено власником',
                details: 'Подробиці',
                final_price: 'Остаточна ціна',
                quantity: 'Кількість',
                size: 'Розмiр',
                processed: 'Обробелено',
                ordered: 'Замовлено',
                delivered: 'Доставлено',
                refunded: 'Повернено',
                canceled: 'Скасовано',
                order: 'Замовлення',
                ordered_date: 'Дата замовлення',
                personal_data: 'Особисті дані',
                delivery_data: 'Дані доставки',
                update: 'Оновити',
                profile: 'Профіль',
                orders: 'Замовлення',
                clear_favorites: 'Очистити вибране',
                not_required: 'Не обов`язково',
                excluding_delivery: 'Без доставки',
                total: 'Всього',
                delivery_address: 'Адреса доставки',
                delivery_information: 'Дані доставки',
                confirm_order: 'Пiдтвердити замовлення',
                no_sizes_added: 'Розміри не додані',
                delete: 'Видалити',
                select_sizes: 'Оберіть розміри',
                shown: 'Зображено',
                languages: 'Мови',
                back_to_stores: 'Повернутися до магазинiв',
                ok: 'Ок',
                search: 'Знайти',
                language: 'Мова',
                wrong_key: 'Неправильний ключ!',
                approve: 'Пiдтвердити',
                ready: 'Готово',
                clear_filters: 'Очистити фільтри',
                clear: 'Очистити',
                filters: 'Фільтри',
                nothing_was_found: 'Нічого не знайдено',
                stores: 'Магазини',
                catalog: 'Каталог',
                find_store_by_name: 'Знайти магазин по назві',
                favorites: 'Уподобане',
                my_stores: 'Мої магазини',
                login: 'Вхід',
                register: 'Зареєструватися',
                user_not_registered: 'Користувач з такою поштою не зареєстрований',
                forgot_password: 'Забули пароль?',
                cart: 'Кошик',
                vendor_code: 'Артикул',
                price: 'Ціна',
                logout: 'Вийти',
                submit: 'Підтвердити',
                description: 'Опис',
                added: 'Додано',
                add_to: 'Додати до',
                first_name: `Ім'я`,
                last_name: 'Прізвище',
                phone_number: 'Номер телефону',
                city: 'Місто',
                email: 'Електронна пошта',
                password: 'Пароль',
                confirm_password: 'Підтвердити пароль',
                company_name: 'Назва компанії',
                already_registered: 'Вже зареєстровані',
                do_want_to_logout: 'Ви дійсно хочете залишити обліковий запис?',
                cancel: 'Скасувати',
                clear_cart: 'Очистити кошик',
                do_you_want_to_clear_cart: 'Бажаєте очистити кошик?',
                enter_password: 'Введіть пароль',
                enter_email: 'Введіть електронну пошту',
                enter_valid_email: 'Введіть дійсну електронну пошту',
                password_length_min_4_symbols: 'Довжина пароля не менше 4 символів',
                passwords_do_not_match: 'Паролі не збігаються',
                enter_first_name: `Введіть ім'я`,
                enter_last_name: 'Введіть прізвище',
                enter_phone_number: 'Введіть номер телефону',
                my_profile: 'Профіль',
                country: 'Країна',
                e_a: 'од.',
                reset_password: 'Скинути пароль',
                close: 'Закрити',
                find_by_vendor_code: 'Знайти модель за артикулом',
                colors: 'Кольори',
                color: 'Колiр',
                sizes: 'Розміри',
                contacts: 'Контакти',
                name: 'Назва',
                no_results: 'Немає результатів',
                loading: 'Завантаження',
                phone_length_minimum_7_characters: 'Довжина телефону не менше 7 символів',
                phone_length_max_14_characters: 'Максимальна довжина телефону 14 символів',
                size_chart: 'Таблиця розмірів',
                load_more: 'Завантажити більше',
                out_of: 'з',
                models: 'моделей',
                in_order_to_use_this_option_you_must_log_in_or_register:
                    'Щоб скористатися цією опцією, ви повинні увійти або зареєструватися.',
                you_need_to_login: 'Потрібно увійти!',
                sended: 'Надіслано',
                store_key: 'Ключ магазину',
                enter_store_key: 'Введіть ключ магазину!',
                language_error: 'Помилка мови!',
                about_store: 'Про магазин',
                payments_delivery: 'Оплата та доставка',
                return_exchange: 'Повернення та обмін',
                privacy_policy: 'Політика конфіденційності',
                info_title_1: `Про Alberto Bini Shop`,
                info_title_2: 'Платежі та Доставка',
                info_title_3: 'Повернення та Обмін',
                info_title_4: 'Політика конфіденційності',
                message: 'Повідомлення',
                info: 'Інформація',
                item: 'товар(и)',
                add_more: 'Додати Більше',
                retail_catalog: 'Роздрібний',
                wholesale_catalog: 'Оптовий',
                web_mob_view: 'Перегляд в Мережі та на Мобільному',
                examples: 'Приклади',
                main_advantages: 'Основні Переваги',
                online_catalog_for_your_business: 'Онлайн-каталог для вашого бізнесу',
                request_sended_successfully: `Запит успішно надіслано! Ми зв'яжемося з вами найближчим часом.`,
                first_month_free: 'Перший місяць безкоштовно',
                home: 'Головна',
                hero_text: `Онлайн-каталог товарів - це програмне рішення, яке дозволяє створювати електронні каталоги з описом товарів. Він пропонує функції для легкого додавання товарів, класифікації, опису, додавання фотографій та іншої важливої інформації. Користувачі можуть організовувати товари в різні категорії, налаштовувати зручні фільтри, виконувати пошук для спрощення навігації. Його часто використовують компанії для створення віртуальних торгових каталогів, візуалізації проектів, онлайн-магазинів, презентації товарів на виставках та презентаціях.`,
                main_function: `Основні Функції`,
                admin_panel: 'Панель Адміністратора',
                admin: 'Адміністратори',
                products_models_photo: 'Продукти/Моделі/Фото',
                catalog_lang: 'Мови Каталогу',
                customization: 'Персоналізація',
                owners: 'Для Власників',
                customers: 'Для Клієнтів',
                request: 'Запит',
                show_all: 'Показати Все',
                unlimited: 'Без обмежень',
                month: 'Місяць',
                i_want: 'Хочу Замовити',
                request_catalog: 'Замовити Каталог',
                consultation: 'Замовити Консультацію',
                request_example: 'Замовити Приклад',
                plan: 'План',
                comment: 'Коментар',
                required_field: `Обов'язкове поле`,
                question: 'Питання',
                send: 'Надіслати',
                enter_new_password: 'Введіть новий пароль',
                clear_form: 'Очистити форму',
                password_changed: 'Пароль змінено',
                add_to_cart: 'Додати в кошик',
                in_cart: 'У кошику',
                menu: 'Меню',
                save_delivery_info: 'Зберегти інформацію про доставку',
                available_sizes: 'Доступні розміри',
                important: 'Важливо',

                copied_to_clipboard: 'Скопійовано до буферу обміну',
                security_check: 'Перевірка безпеки',
                more: 'Бiльше',
                less: 'Меньше',
                find_by_store_name: 'Знайти за назвою магазину',
                subscribes: 'Пiдписки',
                view_your_subscriptions_please_register_or_login:
                    'Щоб побачити список ваших підписок, будь ласка, зареєструйтеся або увійдіть в акаунт.',
                stay_on: 'Залишатися на',
                go_to: 'Перейти на',
                discounts_: 'Знижки',
                support_team: 'Технiчна Пiдтримка',
            },
        },
    ];

    useEffect(() => {
        setCurrentLanguage(langList.find(({ code }) => code === lang) || { code: '', string: {} }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return { currentLanguage };
};
