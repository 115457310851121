import { Box } from '@mui/material';
import { Color } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import Marquee from 'react-fast-marquee';

// Helper function to wrap images inside Box
const imageBox = (src: string, alt: string) => (
    <Box
        key={src}
        p={1}
        px={0.5}
        sx={{ height: 'calc(100vh - 50px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
        <Box sx={{ height: '100%', overflow: 'hidden', borderRadius: 4 }}>
            <img style={{ height: '100%', width: 'auto', overflow: 'hidden' }} src={src} alt={alt} />
        </Box>
    </Box>
);

const Homebanners = () => {
    const height = Math.max(window.screen.height, window.innerHeight);
    const { s } = useDevice();

    // Load images dynamically
    const images = Array.from({ length: 12 }, (_, index) => require(`./images/${index + 1}.jpg`));

    return (
        <Box sx={{ position: 'fixed', top: 52, left: 0, zIndex: 0, overflow: 'hidden' }}>
            <Box
                style={{
                    overflow: 'hidden',
                    height: height,
                    background: Color?.SECONDARY_DARK,
                    maxWidth: '100vw',
                }}
            >
                <Marquee loop={0} speed={s ? 65 : 75}>
                    {images.map((src, index) => imageBox(src, `Loading image ${index + 1}`))}
                </Marquee>
            </Box>
        </Box>
    );
};

export default Homebanners;
