import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const KREMEN_BELTS = {
    storage_key: 'kremen_b',
    active: true, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'Kremen Belts',
    store_code: 'DEFAULT',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Alberto Bini Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: false,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 4, height: 3 },
        main_price_multiplication: 1,
        sale_price_multiplication: 3.5,
        currency_multiplication: 40,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: '₴',
        custom_logo: false,
        orderPriority: 30,
        plan_options: {
            prices: true,
            sizes: false,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/kremen_store.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Наші ремені – це ідеальне доповнення до вашого образу. Виготовлені з високоякісних матеріалів, вони поєднують стиль та функціональність. У нашій колекції ви знайдете різноманітні дизайни та кольори, що дозволять підкреслити вашу індивідуальність. Обирайте ремені, які підходять для будь-якої нагоди та доповнюйте свій стиль.',
            },
            {
                language: 'en',
                description:
                    "Our belts are the perfect accessory to complete your look. Made from high-quality materials, they combine style and functionality. In our collection, you'll find a variety of designs and colors that highlight your individuality. Choose belts suitable for any occasion and elevate your style.",
            },
        ],
        types: [2, 37, 38, 1000],
        gender: [GenderType?.FEMALE, GenderType?.MALE],
        rank: 2,
    },
};
