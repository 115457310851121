import { useEffect } from 'react';
import { useIsMount } from 'hooks/useIsMount';
import { APP_STORES, DropLinks, useStoreConfig } from 'store_constants/useStoreConfig';
import { AxiosResponse } from 'axios';
import { STORAGE_KEYS } from 'constants/local_storage_keys';
import { FormattedStore, UserDataInterface } from 'types/app_models';
import { DEFAULT_VALUES } from 'defaultData/default';
import { MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';
import { Store_Data_Response_Interface, Stores_List_Response_Interface } from 'types/response_models';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { getStorageItem, setStorageItem } from 'utils/storageUtils';

interface Props {
    lang: string;
    auth: boolean | null;
    viewMode: ViewModeType | null;
    storeDataRes?: AxiosResponse<Store_Data_Response_Interface, any>;
    setCurrentStoreData: (newData: FormattedStore) => void;
    isStoreLoading: boolean;
    setViewMode: (newViewMode: ViewModeType) => void;
    setAuth: (newAuth: boolean) => void;
    setLang: (newLang: string) => void;
    userData: {
        currentUserData: UserDataInterface | null;
        isFetchingUser: boolean;
        setCurrentUserData: (newData: UserDataInterface) => void;
        fetchUserData: <TPageData>(
            options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
        ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
        userError: any;
    };
    apiToken: string | null;
    setApiToken: (token: string | null) => void;
    storesListDataRes?: AxiosResponse<Stores_List_Response_Interface[], any>;
    isStoresListLoading: boolean;
    subscribeListDataRes?: AxiosResponse<Stores_List_Response_Interface[], any>;
    isSubscribeListLoading: boolean;
    setCurrentStoresListData: (newData: FormattedStore[]) => void;
    setSubscribeListData: (newData: FormattedStore[]) => void;
}

const StoresLogic = ({
    setAuth,
    auth,
    userData,
    lang,
    setLang,
    viewMode,
    setViewMode,
    storeDataRes,
    setCurrentStoreData,
    isStoreLoading,
    setApiToken,
    apiToken,
    storesListDataRes,
    isStoresListLoading,
    setCurrentStoresListData,
    setSubscribeListData,
    subscribeListDataRes,
    isSubscribeListLoading,
}: Props) => {
    const mount = useIsMount();
    const { APP_LANGUAGE, USER_OPTIONS } = useStoreConfig();
    const { VIEW_MODE } = USER_OPTIONS;

    // authorization
    useEffect(() => {
        const fetchAuth = async () => {
            try {
                const storedItems = await getStorageItem(STORAGE_KEYS?.ACCESS_TOKEN_KEY);

                if (storedItems) {
                    userData
                        .fetchUserData()
                        .then(res => {
                            if (res.status === 'error') {
                                setAuth(false);
                            } else {
                                setAuth(true);
                                setApiToken(storedItems);
                                userData.setCurrentUserData(res?.data?.data);
                            }
                        })
                        .catch(err => {
                            setAuth(false);
                            console.log(err);
                        });
                } else {
                    setAuth(false);
                }
            } catch (error) {
                console.error('Error getting storage item:', error);
            }
        };

        fetchAuth();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!auth) return;
        const fetchAuth = async () => {
            try {
                const storedItems = await getStorageItem(STORAGE_KEYS?.ACCESS_TOKEN_KEY);

                if (storedItems || apiToken) {
                    userData
                        .fetchUserData()
                        .then(res => {
                            if (res.status === 'error') {
                                setAuth(false);
                            } else {
                                userData.setCurrentUserData(res?.data?.data);
                            }
                        })
                        .catch(err => {
                            setAuth(false);
                            console.log(err);
                        });
                } else {
                    setAuth(false);
                }
            } catch (error) {
                console.error('Error getting storage item:', error);
            }
        };

        fetchAuth();
    }, [auth]); // eslint-disable-line

    // set app user lang
    useEffect(() => {
        const fetchLang = async () => {
            try {
                const storedItems = await getStorageItem(STORAGE_KEYS?.LANGUAGE_KEY);
                if (storedItems) {
                    setLang(JSON.parse(storedItems));
                } else {
                    setLang(APP_LANGUAGE);
                    await setStorageItem(STORAGE_KEYS?.LANGUAGE_KEY, JSON.stringify(APP_LANGUAGE));
                }
            } catch (error) {
                console.error('Error getting storage item:', error);
            }
        };

        fetchLang();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (mount) return;
        const updateLang = async () => {
            try {
                await setStorageItem(STORAGE_KEYS?.LANGUAGE_KEY, JSON.stringify(lang));
            } catch (error) {
                console.error('Error setting storage item:', error);
            }
        };

        updateLang();
    }, [lang, mount]); // eslint-disable-line

    // set app user view mode of catalog list
    useEffect(() => {
        const fetchViewMode = async () => {
            try {
                const storedItems = await getStorageItem(STORAGE_KEYS?.VIEW_MODE_KEY);
                if (storedItems) {
                    setViewMode(ViewModeType[JSON.parse(storedItems)]);
                } else {
                    setViewMode(VIEW_MODE);
                    await setStorageItem(STORAGE_KEYS?.VIEW_MODE_KEY, JSON.stringify(VIEW_MODE));
                }
            } catch (error) {
                console.error('Error getting storage item:', error);
            }
        };

        fetchViewMode();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (mount || !viewMode) return;
        const updateViewMode = async () => {
            try {
                await setStorageItem(STORAGE_KEYS?.VIEW_MODE_KEY, JSON.stringify(ViewModeType[viewMode]));
            } catch (error) {
                console.error('Error setting storage item:', error);
            }
        };

        updateViewMode();
    }, [viewMode, mount]); // eslint-disable-line

    useEffect(() => {
        if (!storeDataRes || isStoreLoading) return;

        const store = storeDataRes?.data;
        const featureBEData = APP_STORES?.find(app_store => app_store?.store_code === store?.code);

        const storeData: FormattedStore = {
            id: store?.id,
            currency: store?.currency || DEFAULT_VALUES?.currency,
            logo: { path: store?.logo?.path || DEFAULT_VALUES?.logo, name: '' },
            supportedLanguages: store?.supportedLanguages,
            code: store?.code,
            name: store?.name,
            managers:
                featureBEData?.managers?.map(manager => ({
                    firstName: manager?.firstName || '',
                    lastName: manager?.lastName || '',
                    emailAddress: manager?.emailAddress || '',
                    options: { manager: manager?.options?.manager || false },
                    contacts: {
                        emailAddress: manager?.contacts?.emailAddress || '',
                        phone: manager?.contacts?.phone || '',
                        viber: manager?.contacts?.viber || '',
                        whatsapp: manager?.contacts?.whatsapp || '',
                        telegram: manager?.contacts?.telegram || '',
                    },
                })) || [],
            options: {
                discounts: featureBEData?.options?.discounts,
                webUrl: featureBEData?.options?.webUrl || null,
                telegram_bot: featureBEData?.options?.telegram_bot || [],
                product_image_options: featureBEData?.options?.product_image_options || { width: 6, height: 9 },
                main_price_multiplication: featureBEData?.options?.main_price_multiplication || 1,
                sale_price_multiplication: featureBEData?.options?.sale_price_multiplication || 1,
                currency_multiplication: featureBEData?.options?.currency_multiplication || 1,
                retail_price_multiplication: featureBEData?.options?.retail_price_multiplication || 1,
                min_items_to_buy: featureBEData?.options?.min_items_to_buy || 1,
                store_type: featureBEData?.options?.store_type || StoreType?.sales,
                home_page_active: featureBEData?.options?.home_page_active || true,
                informative_page_active: featureBEData?.options?.informative_page_active || true,
                custom_currency: featureBEData?.options?.custom_currency || null,
                custom_logo: featureBEData?.options?.custom_logo || false,
                orderPriority: featureBEData?.options?.orderPriority || 0,
                plan_options: {
                    prices: featureBEData?.options?.plan_options?.prices || false,
                    sizes: featureBEData?.options?.plan_options?.sizes || false,
                    contacts: featureBEData?.options?.plan_options?.contacts || false,
                    feedback: featureBEData?.options?.plan_options?.feedback || false,
                    categories: featureBEData?.options?.plan_options?.categories || false,
                    productShare: featureBEData?.options?.plan_options?.productShare || false,
                    cart: featureBEData?.options?.plan_options?.cart || false,
                    favorites: featureBEData?.options?.plan_options?.favorites || false,
                    tableSizes: featureBEData?.options?.plan_options?.tableSizes || false,
                    marketPlace: featureBEData?.options?.plan_options?.marketPlace || {
                        isShown: false,
                        type: MarketPlaceType?.drop,
                    },
                },
            },
            store: {
                bannerUrl: featureBEData?.store?.bannerUrl,
                descriptions: featureBEData?.store?.descriptions || [],
                types: featureBEData?.store?.types || [],
                gender: featureBEData?.store?.gender || [],
                rank: featureBEData?.store?.rank || 3,
            },
        };

        setCurrentStoreData(storeData);
    }, [storeDataRes, isStoreLoading]); // eslint-disable-line

    useEffect(() => {
        if (!storesListDataRes || isStoresListLoading) return;

        const storesList = storesListDataRes?.data;

        if (Array.isArray(storesList)) {
            const formattedStoresList: FormattedStore[] = storesList.map(store => {
                const featureBEData = APP_STORES?.find(app_store => app_store?.store_code === store?.code);

                return {
                    orderPriority: featureBEData?.options?.orderPriority || 0,
                    address: {
                        stateProvince: store.address?.stateProvince || '',
                        country: store.address?.country || '',
                        address: store.address?.address || '',
                        postalCode: store.address?.postalCode || '',
                        city: store.address?.city || '',
                        active: store.address?.active || false,
                    },
                    code: store.code || '',
                    currency: store.currency || '',
                    defaultLanguage: store.defaultLanguage || '',
                    email: store.email || '',
                    id: store.id || '',
                    inBusinessSince: store.inBusinessSince || '',
                    logo: store.logo || {
                        name: 'name',
                        path: '/',
                    },
                    name: store.name || '',
                    phone: store.phone || '',
                    retailer: store.retailer || false,
                    supportedLanguages: store.supportedLanguages || [],
                    managers:
                        featureBEData?.managers?.map(manager => ({
                            firstName: manager?.firstName || '',
                            lastName: manager?.lastName || '',
                            emailAddress: manager?.emailAddress || '',
                            options: { manager: manager?.options?.manager || false },
                            contacts: {
                                emailAddress: manager?.contacts?.emailAddress || '',
                                phone: manager?.contacts?.phone || '',
                                viber: manager?.contacts?.viber || '',
                                whatsapp: manager?.contacts?.whatsapp || '',
                                telegram: manager?.contacts?.telegram || '',
                            },
                        })) || [],
                    options: {
                        discounts: featureBEData?.options?.discounts,
                        webUrl: featureBEData?.options?.webUrl || null,
                        telegram_bot: featureBEData?.options?.telegram_bot || [],
                        product_image_options: featureBEData?.options?.product_image_options || { width: 6, height: 9 },
                        main_price_multiplication: featureBEData?.options?.main_price_multiplication || 1,
                        sale_price_multiplication: featureBEData?.options?.sale_price_multiplication || 1,
                        currency_multiplication: featureBEData?.options?.currency_multiplication || 1,
                        retail_price_multiplication: featureBEData?.options?.retail_price_multiplication || 1,
                        min_items_to_buy: featureBEData?.options?.min_items_to_buy || 1,
                        store_type: featureBEData?.options?.store_type || StoreType?.sales,
                        home_page_active: featureBEData?.options?.home_page_active || true,
                        informative_page_active: featureBEData?.options?.informative_page_active || true,
                        custom_currency: featureBEData?.options?.custom_currency || null,
                        custom_logo: featureBEData?.options?.custom_logo || false,
                        orderPriority: featureBEData?.options?.orderPriority || 0,
                        plan_options: {
                            prices: featureBEData?.options?.plan_options?.prices || false,
                            sizes: featureBEData?.options?.plan_options?.sizes || false,
                            contacts: featureBEData?.options?.plan_options?.contacts || false,
                            feedback: featureBEData?.options?.plan_options?.feedback || false,
                            categories: featureBEData?.options?.plan_options?.categories || false,
                            productShare: featureBEData?.options?.plan_options?.productShare || false,
                            cart: featureBEData?.options?.plan_options?.cart || false,
                            favorites: featureBEData?.options?.plan_options?.favorites || false,
                            tableSizes: featureBEData?.options?.plan_options?.tableSizes || false,
                            marketPlace: featureBEData?.options?.plan_options?.marketPlace || {
                                isShown: false,
                                type: MarketPlaceType?.drop,
                            },
                        },
                    },
                    store: {
                        bannerUrl: featureBEData?.store?.bannerUrl || '',
                        gender: featureBEData?.store?.gender || [],
                        descriptions: featureBEData?.store?.descriptions || [],
                        types: featureBEData?.store?.types || [],
                        rank: featureBEData?.store?.rank || 3,
                    },
                };
            });

            const currentUrl = window.location.origin;
            // const isSingleApp = !DropLinks.includes(currentUrl);
            const isDropApp = DropLinks.includes(currentUrl);

            let currentAppTypeStores;

            switch (true) {
                case isDropApp:
                    currentAppTypeStores = formattedStoresList.filter(
                        store =>
                            APP_STORES?.find(app_store => app_store?.store_code === store?.code)?.options?.plan_options
                                ?.marketPlace?.isShown &&
                            APP_STORES?.find(app_store => app_store?.store_code === store?.code)?.options?.plan_options
                                ?.marketPlace?.type === MarketPlaceType?.drop &&
                            APP_STORES?.find(app_store => app_store?.store_code === store?.code)?.active
                    );
                    break;
                default:
                    currentAppTypeStores = []; // Можешь заменить на другое значение, если это не дроп.
                    break;
            }

            setCurrentStoresListData(currentAppTypeStores?.sort((a, b) => a?.orderPriority - b?.orderPriority));
        }
    }, [storesListDataRes, isStoresListLoading]); // eslint-disable-line

    useEffect(() => {
        if (!subscribeListDataRes || isSubscribeListLoading || !auth) return setSubscribeListData([]);

        const subscribeList = subscribeListDataRes?.data;

        if (Array.isArray(subscribeList)) {
            const formattedStoresList: FormattedStore[] = subscribeList.map(store => {
                const featureBEData = APP_STORES?.find(app_store => app_store?.store_code === store?.code);

                return {
                    orderPriority: featureBEData?.options?.orderPriority || 0,
                    address: {
                        stateProvince: store.address?.stateProvince || '',
                        country: store.address?.country || '',
                        address: store.address?.address || '',
                        postalCode: store.address?.postalCode || '',
                        city: store.address?.city || '',
                        active: store.address?.active || false,
                    },
                    code: store.code || '',
                    currency: store.currency || '',
                    defaultLanguage: store.defaultLanguage || '',
                    email: store.email || '',
                    id: store.id || '',
                    inBusinessSince: store.inBusinessSince || '',
                    logo: store.logo || {
                        name: 'name',
                        path: '/',
                    },
                    name: store.name || '',
                    phone: store.phone || '',
                    retailer: store.retailer || false,
                    supportedLanguages: store.supportedLanguages || [],
                    managers:
                        featureBEData?.managers?.map(manager => ({
                            firstName: manager?.firstName || '',
                            lastName: manager?.lastName || '',
                            emailAddress: manager?.emailAddress || '',
                            options: { manager: manager?.options?.manager || false },
                            contacts: {
                                emailAddress: manager?.contacts?.emailAddress || '',
                                phone: manager?.contacts?.phone || '',
                                viber: manager?.contacts?.viber || '',
                                whatsapp: manager?.contacts?.whatsapp || '',
                                telegram: manager?.contacts?.telegram || '',
                            },
                        })) || [],
                    options: {
                        discounts: featureBEData?.options?.discounts,
                        webUrl: featureBEData?.options?.webUrl || null,
                        telegram_bot: featureBEData?.options?.telegram_bot || [],
                        product_image_options: featureBEData?.options?.product_image_options || { width: 6, height: 9 },
                        main_price_multiplication: featureBEData?.options?.main_price_multiplication || 1,
                        sale_price_multiplication: featureBEData?.options?.sale_price_multiplication || 1,
                        currency_multiplication: featureBEData?.options?.currency_multiplication || 1,
                        retail_price_multiplication: featureBEData?.options?.retail_price_multiplication || 1,
                        min_items_to_buy: featureBEData?.options?.min_items_to_buy || 1,
                        store_type: featureBEData?.options?.store_type || StoreType?.sales,
                        home_page_active: featureBEData?.options?.home_page_active || true,
                        informative_page_active: featureBEData?.options?.informative_page_active || true,
                        custom_currency: featureBEData?.options?.custom_currency || null,
                        custom_logo: featureBEData?.options?.custom_logo || false,
                        orderPriority: featureBEData?.options?.orderPriority || 0,
                        plan_options: {
                            prices: featureBEData?.options?.plan_options?.prices || false,
                            sizes: featureBEData?.options?.plan_options?.sizes || false,
                            contacts: featureBEData?.options?.plan_options?.contacts || false,
                            feedback: featureBEData?.options?.plan_options?.feedback || false,
                            categories: featureBEData?.options?.plan_options?.categories || false,
                            productShare: featureBEData?.options?.plan_options?.productShare || false,
                            cart: featureBEData?.options?.plan_options?.cart || false,
                            favorites: featureBEData?.options?.plan_options?.favorites || false,
                            tableSizes: featureBEData?.options?.plan_options?.tableSizes || false,
                            marketPlace: featureBEData?.options?.plan_options?.marketPlace || {
                                isShown: false,
                                type: MarketPlaceType?.drop,
                            },
                        },
                    },
                    store: {
                        bannerUrl: featureBEData?.store?.bannerUrl || '',
                        gender: featureBEData?.store?.gender || [],
                        descriptions: featureBEData?.store?.descriptions || [],
                        types: featureBEData?.store?.types || [],
                        rank: featureBEData?.store?.rank || 3,
                    },
                };
            });

            const DropLinks = ['https://albertobini-brandeurope.com', 'http://localhost:3000'];

            const currentUrl = window.location.origin;
            // const isSingleApp = !DropLinks.includes(currentUrl);
            const isDropApp = DropLinks.includes(currentUrl);

            let currentAppTypeStores;

            switch (true) {
                case isDropApp:
                    currentAppTypeStores = formattedStoresList.filter(
                        store =>
                            APP_STORES?.find(app_store => app_store?.store_code === store?.code)?.options?.plan_options
                                ?.marketPlace?.isShown &&
                            APP_STORES?.find(app_store => app_store?.store_code === store?.code)?.options?.plan_options
                                ?.marketPlace?.type === MarketPlaceType?.drop
                    );
                    break;
                default:
                    currentAppTypeStores = []; // Можешь заменить на другое значение, если это не дроп.
                    break;
            }

            setSubscribeListData(currentAppTypeStores?.sort((a, b) => a?.orderPriority - b?.orderPriority));
        }
    }, [subscribeListDataRes, isSubscribeListLoading, auth]); // eslint-disable-line
};

export default StoresLogic;
