import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const HM_AlbertoBini = {
    storage_key: 'HM_',
    active: false, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'H&M',
    store_code: 'hm',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Alberto Bini Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: false,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6.75, height: 9 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: false,
        orderPriority: 90,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/hm_.jpg`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'H&M — бренд, відомий стильним та доступним одягом для чоловіків, жінок і дітей. Заснований у 1947 році в Швеції, H&M прагне до сталого розвитку, пропонуючи модні колекції та співпраці з відомими дизайнерами.',
            },
            {
                language: 'en',
                description:
                    'H&M is a brand known for stylish and affordable clothing for men, women, and children. Founded in 1947 in Sweden, H&M focuses on sustainability, offering trendy collections and collaborations with renowned designers.',
            },
        ],
        types: [5, 6, 12, 14, 16, 39, 65, 1000],
        gender: [GenderType?.FAMILY],
        rank: 2,
    },
};
