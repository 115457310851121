export const INFORMATION = {
    en: {
        info_text_1:
            'Alberto Bini is a Ukrainian marketplace that brings together stores of Ukrainian and international brands. Each store operates as an independent entity with its own infrastructure, including a shopping cart, wishlist, order reports, and store contacts.',
        info_text_2: `1. PAYMENT:\n
         After placing an order, payment is made by transferring funds to the provided bank account.
         Payment is made to the account of the respective store represented on the Alberto Bini marketplace.\n
         Cash on delivery is also available in accordance with postal rules in Ukraine.\n\n
         2. DELIVERY:\n
         Delivery is carried out within 2 to 15 working days, depending on the conditions of the specific store represented on the platform.\n\n
         After placing an order, a manager will contact you to clarify the details regarding payment methods, transfer details, and delivery terms.`,
        info_text_3: `RETURNS:\n
         - Returns are only possible at the post office upon receiving the product.\n
         - The buyer must verify the quality of the product at the post office when receiving it. If the product does not meet expectations or is defective, the buyer can return it directly at the post office.\n
         - Once the buyer takes the product home from the post office, no returns are accepted.\n
         EXCHANGES:\n
         - Exchanges are possible only at the post office under the same conditions as returns.\n
         REFUNDS:\n
         - Refunds are processed back to the original payment method within 7-10 working days after the item is received and inspected at the post office.`,
        info_text_4: `1. GENERAL PROVISIONS\n
         - 1.1. By using the marketplace, the user agrees to this Privacy Policy and the terms for processing the user's personal data.\n
         - 1.2. If the user does not agree to the terms of the Privacy Policy, they must stop using the marketplace.\n
         - 1.3. This Privacy Policy applies only to this marketplace. The administration does not control and is not responsible for third-party websites that the user may visit through links on the marketplace.\n
         - 1.4. The administration does not verify the accuracy of personal data provided by the user.\n
         \n
         2. PURPOSE OF THE PRIVACY POLICY\n
         - 2.1. This Privacy Policy establishes the obligations of the marketplace administration regarding non-disclosure and protection of personal data provided by the user at the request of the administration.\n
         - 2.2. Personal data authorized for processing under this Privacy Policy is provided by the user through the registration form on the marketplace.\n
         \n
         3. PURPOSES OF COLLECTING USER'S PERSONAL INFORMATION\n
         - 3.1. The administration may use the user's personal data for the following purposes:\n
         - 3.1.1. Identifying the user for order processing.\n
         - 3.1.2. Providing the user with access to personalized resources of the marketplace.\n
         - 3.1.3. Establishing feedback with the user, including sending notifications, inquiries regarding the use of the marketplace, providing services, and processing requests and applications.\n
         - 3.1.4. Determining the user's location to ensure security and prevent fraud.\n
         - 3.1.5. Verifying the accuracy and completeness of personal data provided by the user.\n
         - 3.1.6. Informing the user about the status of their order.\n
         - 3.1.7. Providing effective customer support in case of issues related to the use of the marketplace.\n
         - 3.1.8. Providing the user, with their consent, product updates, special offers, pricing information, and newsletters on behalf of the marketplace or its partners.\n
         - 3.1.9. Conducting promotional activities with the user’s consent.\n
         - 3.1.10. Providing the user with access to partners' websites or services for the purpose of receiving products, updates, and services.\n
         \n
         4. OBLIGATIONS OF THE PARTIES\n
         - 4.1. The user is obligated to:\n
         - 4.1.1. Provide personal data necessary for using the marketplace.\n
         - 4.1.2. Update and supplement the provided personal data if it changes.\n
         - 4.2. The administration is obligated to:\n
         - 4.2.1. Use the information obtained solely for the purposes outlined in Section 3 of this Policy.\n
         - 4.2.2. Ensure the confidentiality of confidential information without the user's consent, and not to sell, exchange, publish, or disclose the user's personal data by any other means.\n
         - 4.2.3. Take measures to protect the confidentiality of the user's personal data in accordance with the laws of Ukraine.\n
         - 4.2.4. Block the user's personal data upon request of the user or their legal representative if inaccuracies are found.\n
         \n
         5. LIABILITY OF THE PARTIES\n
         - 5.1. The administration is responsible for damages incurred by the user due to the unlawful use of personal data in accordance with the laws of Ukraine.\n
         - 5.2. In case of loss or disclosure of confidential information, the administration is not responsible if the information became publicly known or was obtained from a third party before being received by the administration.\n
         \n
         6. DISPUTE RESOLUTION\n
         - 6.1. Before filing a lawsuit, the parties must attempt to resolve the dispute through a written claim.\n
         - 6.2. The respondent must inform the claimant of the outcome of the claim within 30 calendar days.\n
         - 6.3. If the dispute cannot be resolved through negotiations, it is submitted to the court in accordance with the laws of Ukraine.\n
         \n
         7. ADDITIONAL TERMS\n
         - 7.1. The administration reserves the right to make changes to this Privacy Policy without the user's consent.\n
         - 7.2. The new Privacy Policy takes effect from the moment it is published on the marketplace, unless otherwise provided by the new version of the Policy.\n`,
    },

    ua: {
        info_text_1:
            'Alberto Bini — це український маркетплейс, який об`єднує магазини українських і зарубіжних брендів. Кожен магазин є окремою одиницею зі своєю інфраструктурою, що включає кошик для покупок, список бажань, звіти про замовлення та контакти магазину.',
        info_text_2: `1. ОПЛАТА:\n
           Після оформлення замовлення оплата здійснюється шляхом переказу коштів на вказаний банківський рахунок.
           Оплата здійснюється на рахунок відповідного магазину, який представлений на маркетплейсі Alberto Bini.\n
           Можлива також оплата післяплатою згідно з правилами пошти України.\n\n
           2. ДОСТАВКА:\n
           Доставка здійснюється в термін від 2 до 15 робочих днів залежно від умов конкретного магазину, представленого на платформі.\n\n
           Після оформлення замовлення з вами зв'яжеться менеджер для уточнення деталей щодо способів оплати, реквізитів для переказу та умов доставки.`,
        info_text_3: `ПОВЕРНЕННЯ:\n
           - Повернення товару можливе лише в поштовому відділенні при отриманні.\n
           - Покупець має переконатися в якості товару під час отримання на пошті. Якщо товар не відповідає очікуванням або є бракованим, покупець може повернути його безпосередньо на пошті.\n
           - Після того, як покупець забирає товар із поштового відділення, повернення неможливе.\n
           ОБМІН:\n
           - Обмін товару можливий лише в поштовому відділенні за тих самих умов, що і для повернення.\n
           ВІДШКОДУВАННЯ:\n
           - Відшкодування коштів здійснюється на оригінальний спосіб оплати протягом 7-10 робочих днів після отримання та перевірки товару на пошті.`,
        info_text_4: `1. ЗАГАЛЬНІ ПОЛОЖЕННЯ\n
           - 1.1. Використовуючи маркетплейс, користувач погоджується з цією Політикою конфіденційності та умовами обробки персональних даних користувача.\n
           - 1.2. Якщо користувач не погоджується з умовами Політики конфіденційності, він має припинити використання маркетплейсу.\n
           - 1.3. Ця Політика конфіденційності застосовується лише до цього маркетплейсу. Адміністрація не контролює та не несе відповідальності за сторонні сайти, на які користувач може перейти за посиланнями на маркетплейсі.\n
           - 1.4. Адміністрація не перевіряє достовірність персональних даних, наданих користувачем.\n
           \n
           2. ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ\n
           - 2.1. Ця Політика конфіденційності встановлює обов'язки адміністрації маркетплейсу щодо нерозголошення та забезпечення захисту персональних даних, які користувач надає за запитом адміністрації.\n
           - 2.2. Персональні дані, дозволені для обробки в рамках цієї Політики конфіденційності, надаються користувачем шляхом заповнення реєстраційної форми на маркетплейсі.\n
           \n
           3. ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА\n
           - 3.1. Адміністрація може використовувати персональні дані користувача для таких цілей:\n
           - 3.1.1. Ідентифікація користувача для обробки замовлень.\n
           - 3.1.2. Надання користувачу доступу до персоналізованих ресурсів маркетплейсу.\n
           - 3.1.3. Встановлення зворотного зв'язку з користувачем, включаючи надсилання повідомлень, запитів щодо використання маркетплейсу, надання послуг, обробку запитів і заявок.\n
           - 3.1.4. Визначення місцезнаходження користувача для забезпечення безпеки та запобігання шахрайству.\n
           - 3.1.5. Перевірка достовірності та повноти персональних даних, наданих користувачем.\n
           - 3.1.6. Інформування користувача про стан замовлення.\n
           - 3.1.7. Надання ефективної клієнтської підтримки у разі виникнення проблем, пов'язаних з використанням маркетплейсу.\n
           - 3.1.8. Надання користувачу, за його згодою, оновлень продукції, спеціальних пропозицій, інформації про ціни та новинних розсилок від імені маркетплейсу або його партнерів.\n
           - 3.1.9. Проведення рекламних активностей за згодою користувача.\n
           - 3.1.10. Надання користувачу доступу до сайтів або послуг партнерів для отримання продуктів, оновлень і послуг.\n
           \n
           4. ОБОВ'ЯЗКИ СТОРІН\n
           - 4.1. Користувач зобов'язаний:\n
           - 4.1.1. Надати персональні дані, необхідні для використання маркетплейсу.\n
           - 4.1.2. Оновлювати та доповнювати надані персональні дані в разі їх зміни.\n
           - 4.2. Адміністрація зобов'язана:\n
           - 4.2.1. Використовувати отриману інформацію виключно для цілей, зазначених у Розділі 3 цієї Політики.\n
           - 4.2.2. Забезпечувати нерозголошення конфіденційної інформації без згоди користувача, а також не продавати, не обмінювати, не публікувати та не розголошувати будь-якими іншими способами надані персональні дані користувача.\n
           - 4.2.3. Вживати заходів для захисту конфіденційності персональних даних відповідно до законодавства України.\n
           - 4.2.4. Блокувати персональні дані користувача на запит користувача або його законного представника, якщо виявлені неточності.\n
           \n
           5. ВІДПОВІДАЛЬНІСТЬ СТОРІН\n
           - 5.1. Адміністрація несе відповідальність за збитки, завдані користувачу у зв'язку з незаконним використанням персональних даних, відповідно до законодавства України.\n
           - 5.2. У разі втрати або розголошення конфіденційної інформації адміністрація не несе відповідальності, якщо інформація стала загальнодоступною або була отримана від третьої сторони до її отримання адміністрацією.\n
           \n
           6. ВИРІШЕННЯ СПОРІВ\n
           - 6.1. Перед поданням позову сторони повинні спробувати вирішити спір шляхом подання письмової претензії.\n
           - 6.2. Відповідач повинен повідомити позивача про результати розгляду претензії протягом 30 календарних днів.\n
           - 6.3. Якщо спір не вдалося вирішити шляхом переговорів, він передається до суду відповідно до законодавства України.\n
           \n
           7. ДОДАТКОВІ УМОВИ\n
           - 7.1. Адміністрація залишає за собою право вносити зміни до цієї Політики конфіденційності без згоди користувача.\n
           - 7.2. Нова Політика конфіденційності набирає чинності з моменту її публікації на маркетплейсі, якщо інше не передбачено новою редакцією Політики.\n`,
    },
};
