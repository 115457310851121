import axios from 'axios';

export const telegramSender = ({ action, contacts = '', text = '' }) => {
    try {
        const token = '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ';
        const chatId = '480774886';
        const url = `https://api.telegram.org/bot${token}/sendMessage`;

        axios
            .get('https://ipapi.co/json/')
            .then(response => {
                const userCountry = response.data.country_name;
                const userCity = response.data.city;

                axios.post(url, {
                    chat_id: chatId,
                    text:
                        `${action} - ${'Albert Bini SHOP'} - ${userCountry + '/' + userCity} ` +
                        ' ' +
                        `${contacts ? ` - (contacts) ${contacts}` : ''}` +
                        ' ' +
                        `${text ? ` - (text) ${text}` : ''}`,
                });
            })
            .catch(error => {
                console.error(error);
            });
    } catch (error) {
        console.error(error);
    }
};
