import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const SASSOFONO = {
    storage_key: 'sassofono',
    active: true, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'Sassofono',
    store_code: 'sassofono',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Alberto Bini Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: true,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6.75, height: 9 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: false,
        orderPriority: 10,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/sassofono.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'SASSOFONO — провідний текстильний бренд, заснований у 2001 році. Компанія виробляє жіночий одяг і аксесуари, включаючи сукні, жакети, джинси та тренчкоти, у власних потужностях у Стамбулі. SASSOFONO експортує продукцію до багатьох країн, зберігаючи високу якість і сучасний стиль у кожній колекції.',
            },
            {
                language: 'en',
                description:
                    'SASSOFONO is a leading textile brand founded in 2001. The company produces women`s clothing and accessories, including dresses, jackets, jeans, and trench coats, in its own facilities in Istanbul. SASSOFONO exports its products to numerous countries, maintaining high quality and modern style in every collection.',
            },
        ],
        types: [45, 39, 31, 14, 13, 12, 6, 4, 1000],
        gender: [GenderType?.FEMALE],
        rank: 1,
    },
};
