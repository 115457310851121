import { Outlet, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './MainCatalogHeader';
import MobileMenu from './MainCatalogMobileMenu';
import { useDevice } from 'hooks/useDevice';
import { useState, useCallback, useEffect } from 'react';
import { useCategory } from '../hooks/useCategory';
import { useProducts } from '../hooks/useProducts';
import { CatalogContextInterface } from 'types/outlet_context_models';
import { useFormsApp } from 'layouts/hooks/useFormsApp';
import DialogApp from 'layouts/DialogApp';
import { ROUTES } from 'router/routes';
import { ViewModeType } from 'store_constants/types';
import {
    FormattedStore,
    UserDataInterface,
    useAddToCartDataInterface,
    useAddToFavoriteDataInterface,
} from 'types/app_models';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { LanguageDataInterface } from 'hooks/useGetLanguage';
import { useFavorites } from 'layouts/hooks/useFavorites';
import Loader from 'components/atoms/Loader/Loader';
import { useUserApi } from 'api/useUserApi';
import { scrollPage } from 'utils/scrollPage';
import { setStorageItem } from 'utils/storageUtils';
import { STORAGE_KEYS } from 'constants/local_storage_keys';

interface Props {
    apiToken: string | null;
    setApiToken: (token: string | null) => void;
    lang: string;
    setViewMode: (newViewMode: ViewModeType) => void;
    setAuth: (newAuth: boolean) => void;
    setLang: (newLang: string) => void;
    auth: boolean | null;
    userData: {
        currentUserData: UserDataInterface | null;
        isFetchingUser: boolean;
        setCurrentUserData: (newData: UserDataInterface) => void;
        fetchUserData: <TPageData>(
            options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
        ) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
        userError: any;
    };
    viewMode: ViewModeType | null;
    storesList?: FormattedStore[] | null;
    favorites: useAddToFavoriteDataInterface;
    cart: useAddToCartDataInterface;
    currentLanguage: LanguageDataInterface;
    savedImages: { image: File | Blob; imageUrl: string }[];
    handleSaveImage: (image: { file: File | Blob; imageUrl: string }) => void;
}

const OutletContainer = ({ context }: { context: CatalogContextInterface }) => {
    return <Outlet context={context} />;
};

export default function MainCatalog({
    setLang,
    setAuth,
    setViewMode,
    lang,
    auth,
    userData,
    viewMode,
    storesList,
    favorites,
    cart,
    currentLanguage,
    handleSaveImage,
    savedImages,
    apiToken,
    setApiToken,
}: Props) {
    const { storeCode } = useParams();

    const { sx } = useDevice();
    const HEADER_HEIGHT = 54;
    const FOOTER_MENU_HEIGHT = sx ? 65 : 0;
    const INSTRUMENTAL_BAR_HEIGHT = 36;
    const INSTRUMENTAL_BAR_PADDINGS = sx ? 2 : 4;
    const HEADER_PADDINGS = sx ? 2 : 4;
    const BODY_PADDINGS = sx ? 0 : 4;
    const FOOTER_PADDINGS = sx ? 2 : 4;
    const [scrollPosition, setScrollPosition] = useState(0);
    const { activeDialogWindow, handleOpenDialog, handleSetDialogState, dialogState } = useFormsApp();

    const {
        isLoadingProducts,
        currentProductsPage,
        handleSetProductsPage,
        productsList,
        totalProductsCount,
        productCountPerPage,
        totalProductsPages,
        setProductsList,
        queryCategories,
        setQueryCategories,
    } = useProducts({
        lang,
        store: storeCode || '',
    });
    const [store, setStore] = useState<any>(null);
    const { isLoadingFavorites, favoritesList, fetchFavoriteProducts } = useFavorites({
        lang,
        store: storeCode || '',
        favorites,
    });

    const { categoriesList } = useCategory({
        lang,
        store: storeCode || '',
    });

    const memoizedHandleOpenDialog = useCallback(handleOpenDialog, []); // eslint-disable-line
    const memoizedSetLang = useCallback(setLang, []); // eslint-disable-line
    const memoizedSetAuth = useCallback(setAuth, []); // eslint-disable-line

    const { mutateAsync: loginCustomer } = useUserApi().useCustomerLogin();
    const { mutateAsync: registerCustomer } = useUserApi().useCustomerRegister();

    const selectedStore = storesList?.find(store => store?.code === storeCode) || null;

    useEffect(() => {
        scrollPage(0);
        if (!auth || !userData?.currentUserData || !selectedStore) return;
        const registerInCurrentStore = () => {
            return registerCustomer({
                emailAddress: String(userData?.currentUserData?.emailAddress),
                firstName: String(userData?.currentUserData?.billing?.firstName),
                lastName: String(userData?.currentUserData?.billing?.lastName),
                password: userData?.currentUserData?.emailAddress + 'cocktail_store',
                username: String(userData?.currentUserData?.emailAddress),
                country: String(userData?.currentUserData?.billing?.country),
                phone: String(userData?.currentUserData?.billing?.phone),
                lang: lang,
                storeCode: String(selectedStore?.code),
            }).then(res => {
                setStorageItem(STORAGE_KEYS?.ACCESS_TOKEN_KEY, JSON.stringify(res.data.token));
                setApiToken && setApiToken(res.data.token);
                setAuth(true);
            });
        };

        const loginToCurrenStore = () => {
            return loginCustomer({
                password: userData?.currentUserData?.emailAddress + 'cocktail_store',
                email: String(userData?.currentUserData?.emailAddress),
                storeCode: String(selectedStore?.code),
            })
                .then(res => {
                    if (res.data.token) {
                        setStorageItem(STORAGE_KEYS?.ACCESS_TOKEN_KEY, JSON.stringify(res.data.token));
                        setApiToken && setApiToken(res.data.token);

                        setAuth(true);
                    }
                })
                .catch(err => {
                    registerInCurrentStore();
                });
        };

        if (auth) loginToCurrenStore();
    }, [auth, userData?.currentUserData, selectedStore]); //eslint-disable-line

    useEffect(() => {
        if (!storesList) return;
        setStore(selectedStore);
    }, [storesList, storeCode]); //eslint-disable-line

    useEffect(() => {
        if (!storesList) return;
        setStore(selectedStore);
    }, [storesList, storeCode]); //eslint-disable-line

    if (!store) return <Loader />;

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            <CssBaseline />

            <Header
                headerHeight={HEADER_HEIGHT}
                appXPadding={HEADER_PADDINGS}
                string={currentLanguage?.string}
                lang={lang}
                setLang={memoizedSetLang}
                logo={store?.logo?.path}
                store={store}
                cart={cart}
                favorites={favorites}
                auth={auth}
                user={userData}
                handleOpenDialog={memoizedHandleOpenDialog}
            />

            <Box className="AppBody" mt={`${HEADER_HEIGHT + INSTRUMENTAL_BAR_HEIGHT}px`} sx={{ flexGrow: 1 }}>
                <OutletContainer
                    context={{
                        //main data | user options
                        lang,
                        string: currentLanguage?.string,
                        scrollPosition,
                        setScrollPosition,
                        viewMode,
                        setViewMode,
                        handleOpenDialog,
                        handleSetDialogState,
                        dialogState,
                        handleSaveImage,
                        savedImages,

                        //store data

                        store,

                        //user data
                        auth,
                        apiToken,
                        currentUserData: userData.currentUserData,
                        loadingUserData: userData.isFetchingUser,
                        updateUserData: userData.fetchUserData,
                        setCurrentUserData: userData.setCurrentUserData,
                        userDataError: userData.userError,
                        setAuth,
                        setApiToken,

                        //products data
                        productsList,
                        setProductsList,
                        isLoadingProducts,
                        productCountPerPage,
                        totalProductsCount,
                        totalProductsPages,
                        handleSetProductsPage,
                        currentProductsPage,

                        //favorites data
                        isLoadingFavorites,
                        favoritesList,
                        fetchFavoriteProducts,

                        //categories data
                        categoriesList,
                        queryCategories,
                        setQueryCategories,

                        //css data
                        instrumentalBarHeight: INSTRUMENTAL_BAR_HEIGHT,
                        instrumentalBarPadding: INSTRUMENTAL_BAR_PADDINGS,
                        headerHeight: HEADER_HEIGHT,
                        footerMenuHeight: FOOTER_MENU_HEIGHT,
                        appXPadding: BODY_PADDINGS,

                        //cart & favorites
                        cart,
                        favorites,
                    }}
                />
            </Box>

            <MobileMenu
                menuHeight={FOOTER_MENU_HEIGHT}
                appXPadding={FOOTER_PADDINGS}
                string={currentLanguage?.string}
                auth={auth}
                headerHeight={HEADER_HEIGHT}
                user={userData}
                isShown={!!sx}
                cart={cart}
                favorites={favorites}
                handleOpenDialog={memoizedHandleOpenDialog}
            />
            <DialogApp
                location={ROUTES?.STORE}
                string={currentLanguage?.string}
                activeDialogWindow={activeDialogWindow}
                handleOpenDialog={memoizedHandleOpenDialog}
                setAuth={memoizedSetAuth}
                cart={cart}
                favorites={favorites}
                dialogState={dialogState}
                lang={lang}
                setApiToken={setApiToken}
                handleSetDialogState={handleSetDialogState}
            />
        </Box>
    );
}
